@import "../variables.scss";

.TilesBlock {
  margin: 24px 0;

  .col {
    margin-bottom: 24px;

    a {
      height: 100%;
      color: inherit;
      text-decoration: inherit;
      display: block;

      .card {
        &:hover,
        &:focus,
        &:active {
          box-shadow: var(--box-shadow);
          transition: 1s;
          box-shadow: var(--hover-shadow);
          cursor: pointer;
          -webkit-transform: scale(1.02, 1.02);
          transform: scale(1.02, 1.02);
          transform: none;
        }
      }

      &::after {
        content: none !important;
        border: none !important;
        border-bottom: none !important;
      }
    }

    .card {
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
      padding: 24px;
      text-align: center;

      &-body {
        padding: 0;
      }

      &-title {
        font-size: 24px;
      }

      img {
        width: 120px;
        min-width: 120px;

        margin: auto;
        margin-bottom: 24px;
      }
    }
  }
}

@media only screen and (min-width: $tablet) {
  .TilesBlock {
    margin: 32px 0;

    .col {
      margin-bottom: 32px;

      .card {
        display: flex;
        flex-direction: row;
        padding: 32px;
        text-align: left;
        align-items: center;

        img {
          margin-right: 32px;
          margin-bottom: 0;
          min-width: 150px;
        }
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .TilesBlock {
    margin: 48px 0;

    .col {
      margin-bottom: 0;

      .card {
        flex-direction: column;
        text-align: center;
        height: 100%;

        img {
          margin-right: auto;
          margin-bottom: 32px;
        }
      }
    }
  }
}
