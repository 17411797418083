@import "../variables.scss";

footer {
  background-color: $brand-black;

  .Footer {
    margin: 24px 0;

    &__navigation {
      display: flex;
      justify-content: space-between;
      padding-bottom: 24px;

      > ul li {
        padding: 0 12px 0 0;
        line-height: 42px;
      }
    }

    &__copywrite {
      color: #fff;
    }
  }
}
@media only screen and (min-width: $tablet) {
  footer .Footer {
    margin: 32px 0;
  }
}

@media only screen and (min-width: $desktop) {
  footer .Footer {
    margin: 48px 0;

    &__navigation {
      > ul li {
        padding: 0 24px 0 0;
      }
    }
  }
}
