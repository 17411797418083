$tablet: 768px;
$desktop: 992px;
$primary-color: #ea5a17;
$secondary-color: #5eb1bf;
$brand-black: #042a2b;
$brand-white: #f8f9fa;
$tertiary-color: #fcebd8;
$border-radius: 0.25rem;

:export {
  primary: $primary-color;
  secondary: $secondary-color;
  tertiary: $tertiary-color;
  white: $brand-white;
  black: $brand-black;
}

@mixin button-style($bg, $clr) {
  background: $bg;
  border: solid 1px $bg;
  color: $clr;

  &:focus,
  &:hover {
    background: darken($bg, 8%);
    border-color: $bg;
    transition: all 0.3s ease;
    outline: none;
    box-shadow: none;
  }

  &:active {
    background: darken($bg, 25%);
  }
}

button,
.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: $border-radius;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:disabled {
    background-color: lighten(#36454f, 55%);
    border: solid #36454f 1px;
    color: #36454f;
  }
}
a {
  text-decoration: none;
  display: inline-block;
}

a:not([rel~="external"]):not(.btn) {
  line-height: initial;
  &:after {
    display: block;
    content: "";
    border-bottom: solid 1px #0d6efd;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }

  &:focus:after,
  &:active:after,
  &:hover:after {
    transform: scaleX(1);
    outline: none;
  }

  &:after {
    transform-origin: 0 50%;
  }
}

a[rel~="external"] {
  padding-right: 8px;
  position: relative;

  &::after {
    position: relative;
    left: 8px;
    top: 6px;
    content: url("./Images/external-link.svg");
    transition: 0.3s;
  }

  &:focus,
  &:active,
  &:hover {
    text-decoration: none;
    outline: none;

    &::after {
      left: 16px;
    }
  }
}

.btn-primary {
  @include button-style($primary-color, #fff);
}

.btn-secondary {
  @include button-style($secondary-color, #fff);
}

.btn-inverse {
  @include button-style(#fff, transparent);
}
