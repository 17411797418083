@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Slab&display=swap");
@import "./variables.scss";

html {
  font-size: 100%;
} /*16px*/

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.75;
  color: #000000;
}

p {
  margin-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Roboto Slab", serif;
  font-weight: 400;
}

h1 {
  font-size: 1.802rem;
}

h2 {
  font-size: 1.602rem;
}

h3 {
  font-size: 1.424rem;
}

h4 {
  font-size: 1.266rem;
}

h5 {
  font-size: 1.125rem;
}

small,
.text_small {
  font-size: 0.889rem;
}

@media only screen and (min-width: $tablet) {
  h1 {
    font-size: 3.052rem;
  }

  h2 {
    font-size: 2.441rem;
  }

  h3 {
    font-size: 1.953rem;
  }

  h4 {
    font-size: 1.563rem;
  }

  h5 {
    font-size: 1.25rem;
  }

  small,
  .text_small {
    font-size: 0.8rem;
  }
}
@media only screen and (min-width: $desktop) {
  h1 {
    font-size: 4.209rem;
  }

  h2 {
    font-size: 3.157rem;
  }

  h3 {
    font-size: 2.369rem;
  }

  h4 {
    font-size: 1.777rem;
  }

  h5 {
    font-size: 1.333rem;
  }

  small,
  .text_small {
    font-size: 0.75rem;
  }
}
