@import "./variables.scss";

body {
  background-color: #f8f9fa;
}

section {
  border-bottom: 1px solid lightgray;
}
main {
  margin-top: 81px;
}
.App {
  min-height: 100vh;
}
.full-width {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  padding-left: calc(-50% + 50vw);
  padding-right: calc(-50% + 50vw);
}

.content {
  margin-bottom: 24px;
}

[id]::before {
  content: "";
  display: block;
  height: 81px;
  margin-top: -81px;
  visibility: hidden;
  position: relative;
  z-index: -1;
}

@media only screen and (min-width: $tablet) {
  .content {
    margin-bottom: 32px;
  }
}

@media only screen and (min-width: $desktop) {
  .content {
    margin-bottom: 48px;
  }
}
