@import "../variables.scss";

.Form {
  padding-top: 24px;
  padding-bottom: 24px;

  h2 {
    margin-bottom: 24px;
  }
  form {
    .textarea textarea,
    .input input {
      width: 100%;
      margin-bottom: 24px;
      border: none;
      border-radius: $border-radius;
      transition: 0.3s;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
      padding: 4px 8px;

      &:focus,
      &:active,
      &:hover {
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
        outline: none;
      }
    }
  }
}

@media only screen and (min-width: $tablet) {
  .Form {
    padding-top: 32px;
    padding-bottom: 32px;

    form {
      .input {
        display: inline-block;
        width: 50%;
        padding-left: 16px;
        &:first-of-type {
          padding-right: 16px;
          padding-left: 0;
        }
      }

      .textarea textarea,
      .input input {
        margin-bottom: 32px;
      }

      input {
        line-height: 32px;
      }

      textarea {
        height: 100px;
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .Form {
    padding-top: 48px;
    padding-bottom: 48px;

    h2 {
      text-align: center;
    }

    form {
      width: 50%;
      margin: 0 auto;

      .textarea,
      .input {
        width: 100%;
        display: block;
      }
      .input:first-of-type,
      .input {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
