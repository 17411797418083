@import "./variables.scss";

Footer {
  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    display: inline-block;
    padding: 0 6px;

    a {
      color: #fff;
      font-size: 16px;

      &:after {
        border-bottom: solid 1px #fff !important;
      }

      &:hover {
        color: #fff;
      }
    }
  }
}
