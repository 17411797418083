@import "../variables.scss";

.Blockquote {
  padding-top: 24px;
  padding-bottom: 24px;

  blockquote {
    margin-bottom: 0;
  }
}

blockquote {
  font-size: 1.4em;
  font-style: italic;
  padding: 1.2em 24px 1.2em 48px;
  line-height: 1.6;
  position: relative;

  &::before {
    font-family: Arial;
    content: "\201C";
    font-size: 3em;
    position: absolute;
    left: 8px;
    top: -10px;
  }

  &::after {
    content: "";
  }

  span {
    display: block;
    font-style: normal;
    font-weight: bold;
    margin-top: 1em;
  }

  &.primary-color-text {
    color: $primary-color;
  }
}

@media only screen and (min-width: $tablet) {
  .Blockquote {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  blockquote {
    padding: 1.2em 30px 1.2em 75px;

    &::before {
      font-size: 4em;

      left: 10px;
    }
  }
}

@media only screen and (min-width: $desktop) {
  .Blockquote {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
