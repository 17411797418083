@import "../variables.scss";

.TextWithIllustration {
  margin: 24px 0;

  &__heading {
    margin-bottom: 24px;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__section {
    flex: 1;

    &:first-child {
      margin-bottom: 24px;
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);
    }

    img {
      max-height: 450px;
      width: 100%;
      object-fit: cover;
    }

    .btn__wrapper {
      text-align: center;
      margin-top: 24px;
    }
  }
}

@media only screen and (min-width: $tablet) {
  .TextWithIllustration {
    margin: 32px 0;

    &__heading {
      margin-bottom: 32px;
    }

    &__section {
      &:first-child {
        margin-bottom: 32px;
        margin-left: 0;
        margin-right: 0;
      }

      img {
        max-height: 350px;
        margin-bottom: 0;
      }

      .btn__wrapper {
        margin-top: 32px;
      }
    }
  }
}

@media only screen and (min-width: $desktop) {
  .TextWithIllustration {
    margin: 48px 0;

    &__section {
      img {
        max-height: 400px;
      }

      .btn__wrapper {
        text-align: left;
        // margin-top: 48px;
      }
    }

    &__container {
      &.image-left {
        flex-direction: row;

        .TextWithIllustration__section {
          &:first-child {
            padding-right: 48px;
          }

          &:last-child {
            padding-left: 48px;
          }
        }
      }

      &.image-right {
        flex-direction: row-reverse;

        .TextWithIllustration__section {
          &:first-child {
            padding-left: 48px;
          }

          &:last-child {
            padding-right: 48px;
          }
        }
      }
    }
  }
}
